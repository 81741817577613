import { useEffect } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { Spinner } from '~components';
import { useAccount } from '~hooks';

const Index = () => {
  const router = useRouter();
  const { user } = useAccount();

  useEffect(() => {
    if (!user) {
      router.replace('/login');
    } else {
      if (user.verifiedPhone && user.fullName) {
        if (user.role === 'superadmin') {
          router.replace('/admin');
        } else if (user.role === 'admin') {
          router.replace('/consult-requests');
        } else {
          router.replace(user.role === 'patient' ? '/case' : '/patients');
        }
      }
      if (user.verifiedPhone) {
        router.replace('/onboarding/personal-information');
      } else {
        router.replace('/onboarding/two-factor');
      }
    }
  }, [user]);

  return (
    <>
      <NextSeo title="Loading" noindex nofollow />
      <Spinner height="100vh" />
    </>
  );
};

export default Index;
